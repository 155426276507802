html {
  min-height: 100%;
  position: relative;
}

$footer-height: 50px;

body {
  margin-bottom: $footer-height;

  > main {
    padding-bottom: $footer-height;
  }

  .footer {
    background-color: $gray-900;
    color: $gray-600;
    bottom: 0;
    height: $footer-height;
    position: absolute;
    width: 100%;
  }
}
