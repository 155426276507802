.app-header {
  position: relative;
  height: 500px;
  overflow: hidden;

  .app-header__hero-container {
    filter: grayscale(100);

    .app-header__hero {
      object-fit: fill;
    }
  }

  .app-header__nav {
  }

  .app-header__content {
    background-color: rgba($white, 0.10);

    .app-header_logo {
      width: 828px;
    }
  }
}